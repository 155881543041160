"use client";

import React from "react";
import settings from "../../assets/utils/settings.json";

interface FooterProps {
  logged?: boolean;
}

export function Footer({ logged = false }: FooterProps) {
  const lastUpdate = settings.LastUpdate;

  return (
    <>
      <footer className="shadow dark:bg-neutral-950">
        <div className="w-full max-w-screen-xl mx-auto p-4">
          <div className="flex flex-col items-center justify-between sm:flex-row">
            <a
              href="https://izzat.xyz"
              className="flex items-center mb-4 sm:mb-0"
            >
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                {"<Izzat />"}
              </span>
            </a>
            <ul className="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <a
                  href="https://www.linkedin.com/in/muhammad-izzat-sg/"
                  className="mr-4 hover:underline md:mr-6"
                  target="blank"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/denmoza"
                  className="mr-4 hover:underline md:mr-6"
                  target="blank"
                >
                  GitHub
                </a>
              </li>
            </ul>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2025{" "}
            <a href="https://izzat.xyz" className="hover:underline">
              Izzat
            </a>
            . All Rights Reserved.
          </span>
          <span className="pt-1 block text-sm text-gray-500 sm:text-center dark:text-gray-400">
            Last Updated {lastUpdate}
          </span>
        </div>
      </footer>
    </>
  );
}
