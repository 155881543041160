import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import {
  IconLockOpen,
  IconChevronRight,
  IconArrowBearLeft,
  IconMapPin,
  IconSun,
  IconMoon,
} from "@tabler/icons-react";
import "../../assets/css/intro.css";
import "../../assets/css/fonts.css";
import settings from "../../assets/utils/settings.json";
import TypingEffect from "../../assets/utils/TypingEffect";

export default function Intro() {
  type ToggleOptionsType = "light" | "dark";
  const [theme, setTheme] = useState<ToggleOptionsType>(() => {
    // Set initial theme based on localStorage or default to 'light'
    return localStorage.getItem("theme") === "dark" ? "dark" : "light";
  });

  const storedAuth = localStorage.getItem("isLoggedIn");

  // useEffect(() => {
  //   const currentHour = new Date().getHours();

  //   // Set theme based on time
  //   if (currentHour >= 7 && currentHour <= 18) {
  //     setTheme("light");
  //     document.documentElement.classList.remove("dark");
  //   } else {
  //     setTheme("dark");
  //     document.documentElement.classList.add("dark");
  //   }
  // }, []);

  // Apply the dark mode class to the html element based on 'selected' state
  useEffect(() => {
    const html = document.documentElement;
    if (theme === "dark") {
      html.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      html.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const cancelButtonRef = useRef(null);

  const handleDivClick = () => {
    setOpenDialog(true);
  };

  const handleLogin = () => {
    setOpenLogin(true);
  };

  const lastUpdate = settings.LastUpdate;
  return (
    <div className="bg dark:bg-neutral-950">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8 top-0 right-0 absolute text-gray-900 dark:text-slate-100"
          aria-label="Global"
        >
          {/* <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
          </div> */}
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          {/* <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div> */}
          <div className="hidden lg:flex lg:flex-1 lg:justify-end ">
            <div
              style={{
                width: 25,
                height: 25,
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => {
                setTheme(theme === "light" ? "dark" : "light");
              }}
              className="mr-5"
            >
              <IconSun
                style={{
                  position: "absolute",
                  opacity: theme === "dark" ? 1 : 0,
                  transform: theme === "dark" ? "scale(1)" : "scale(0.5)",
                  transition: "opacity 0.3s ease, transform 0.3s ease",
                }}
                size={25}
              />
              <IconMoon
                style={{
                  position: "absolute",
                  opacity: theme === "light" ? 1 : 0,
                  transform: theme === "light" ? "scale(1)" : "scale(0.5)",
                  transition: "opacity 0.3s ease, transform 0.3s ease",
                }}
                size={25}
              />
            </div>

            {storedAuth === "true" ? (
              <></>
            ) : (
              <a
                onClick={handleLogin}
                className="text-sm font-semibold leading-6 "
                style={{ cursor: "pointer" }}
              >
                <IconLockOpen style={{ display: "inline" }} size={25} />
                <IconChevronRight style={{ display: "inline" }} size={25} />
              </a>
            )}
          </div>
        </nav>
        <Dialog
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                {/* <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div> */}
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      {storedAuth === "true" ? (
        <></>
      ) : (
        <IconArrowBearLeft
          className="test hidden lg:flex dark:text-slate-100"
          size={35}
        />
      )}
      <div className="relative isolate px-6 lg:px-8 text-gray-600 dark:text-slate-100">
        <div className="flex h-screen">
          {/* <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56"> */}
          <div className="m-auto">
            <div className="text-center" style={{ paddingTop: "70px" }}>
              <p className="text-lg leading-8 ">Hello, I'm</p>
              <h1 className="text-4xl font-bold tracking-tight  sm:text-6xl intro-name dark:text-amber-500">
                {"<Izzat>"}
              </h1>
              <p className="mt-2 text-lg leading-8 ">
                Aspiring <TypingEffect text="Software Engineer" />
              </p>

              <p className="text-lg leading-8 ">
                <IconMapPin style={{ display: "inline" }} />
                Singapore
              </p>
              {/* <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get started
              </a>
              <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div> */}
            </div>
            <div
              className="hidden sm:mb-8 sm:flex sm:justify-center mt-10 text-gray-600 dark:text-slate-100"
              onClick={handleDivClick}
              style={{ cursor: "pointer" }}
            >
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 ring-1 ring-gray-900/10 hover:ring-gray-900/20  dark:ring-slate-200/10 dark:hover:ring-slate-200/20">
                <div className="status__light">
                  <div className="status__light__ring"></div>
                  <div className="status__light__led"></div>
                </div>
                <p className="status__message">
                  Currently serving National Service
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2  dark:text-slate-100">
        <div
          className={
            theme === "light"
              ? "header__chevron-down"
              : "header__chevron-down header__chevron--dark"
          }
        ></div>
        <div className="header__chevron-down"></div>
        <div className="header__chevron-down"></div>
      </div>

      <Transition show={openDialog} as={Fragment}>
        <Dialog
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenDialog}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Hi There!{" "}
                          <span className="emoji wave-hand animated"></span>
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            As of {lastUpdate}, I am currently serving National
                            Service until Mid 2026. Thank you for dropping by!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpenDialog(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition show={openLogin} as={Fragment}>
        <Dialog
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenLogin}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white rounded-lg shadow relative dark:bg-gray-700">
                    <div className="flex justify-end p-2">
                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                        data-modal-toggle="authentication-modal"
                        onClick={() => setOpenLogin(false)}
                      >
                        <svg
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    <form
                      className="space-y-6 px-6 lg:px-8 pb-4 sm:pb-6 xl:pb-8"
                      action="#"
                    >
                      <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                        View the full site!
                      </h3>
                      <div>
                        <label
                          htmlFor="password"
                          className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                        >
                          Enter key
                        </label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="••••••••"
                          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          required
                        />
                      </div>

                      <button
                        type="submit"
                        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Let's go!
                      </button>
                      <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                        New here?{" "}
                        <a
                          href="#"
                          className="text-blue-700 hover:underline dark:text-blue-500"
                        >
                          Get your key here!
                        </a>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
