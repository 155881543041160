import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/sections.css";

const Projects: React.FC = () => {
  const [scrollPercent, setScrollPercent] = useState(0);
  const projectsRef = useRef<HTMLDivElement>(null);
  const SCROLL_FACTOR = 0.1; // Adjust this value to control the scroll speed

  const handleScroll = () => {
    const element = projectsRef.current;
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const elementTop = elementRect.top;
      const elementHeight = elementRect.height;
      const windowHeight = window.innerHeight;

      // Calculate scroll percent
      let scrollPercent = 0;

      if (elementTop < windowHeight && elementTop > -elementHeight) {
        const visiblePart = windowHeight - elementTop;
        scrollPercent = Math.min(
          (visiblePart / (windowHeight + elementHeight)) * 100,
          100
        );
      }

      // Apply scaling factor
      scrollPercent *= SCROLL_FACTOR;

      setScrollPercent(scrollPercent);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call to set the scroll percent

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      ref={projectsRef}
      className="relative overflow-hidden h-[60vh] w-full section-hero dark:bg-neutral-950 text-gray-300  dark:text-amber-500"
    >
      <div
        className="section-title absolute top-[10%] left-[-1%] transition-transform ease-out duration-500 font-bold "
        style={{
          transform: `translateX(${scrollPercent * 5.5}%)`,
          fontSize: "min(10vw, 350px)",
        }}
      >
        PROJECTS
      </div>
      <div className="absolute top-[20%] ml-[30%] mr-[5%] text-left text-gray-900 dark:text-slate-100 font-bold">
        <p className="section-para transform laptop:translate-y-[90%] laptop:text-[30px] laptopL:translate-y-[87%] laptopL:text-[38px] desktop:translate-y-[80%] desktop:text-[70px]">
          Selected projects that I have done over the years.
        </p>
      </div>
    </section>
  );
};

export default Projects;
