import "../../assets/css/intro.css";
import "../../assets/css/fonts.css";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Card, CardDescription, CardTitle } from "../ui/cards";
import { IconExternalLink } from "@tabler/icons-react";
import { motion } from "framer-motion";

export default function ExperienceTab() {
  return (
    <>
      <div className="bg dark:bg-neutral-950 text-gray-700 dark:text-slate-100 flex items-center justify-center">
        <div className="w-4/6">
          <Tabs defaultValue="experience" className="max-w-screen-xl w-full">
            <TabsList className="grid w-full grid-cols-2 border-b border-gray-300 bg-neutral-100 dark:bg-neutral-900 dark:border-gray-800">
              <TabsTrigger
                value="experience"
                className="text-center rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
              >
                Work Experience
              </TabsTrigger>
              <TabsTrigger
                value="education"
                className="text-center rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
              >
                Education
              </TabsTrigger>
            </TabsList>
            <TabsContent value="experience">
              <Card className="bg-slate-50 dark:bg-neutral-900">
                <ol className="relative ml-12 mr-12">
                  <div className="absolute top-0 left-0 bottom-0 w-px h-full bg-gray-200 dark:bg-amber-500"></div>
                  <li className="ms-6 py-4">
                    <span className="absolute flex items-center justify-center size-10 mt-2 bg-blue-100 rounded-full -start-5 ring-4 ring-white dark:ring-amber-500 dark:bg-blue-900">
                      <img
                        className="rounded-full shadow-lg"
                        src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d2/Crest_of_Singapore_Army.svg/1200px-Crest_of_Singapore_Army.svg.png"
                        alt="Singapore Armed Forces Logo"
                      />
                    </span>
                    <motion.div
                      key={1}
                      className=""
                      initial={{ opacity: 0, x: -10 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 1 * 0.2 }}
                      viewport={{ amount: 0.1 }}
                    >
                      <div className="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg ml-5 shadow-sm sm:flex dark:bg-neutral-800 dark:border-neutral-600">
                        <a
                          href=""
                          className="mb-1 text-xs sm:order-last sm:mb-0 font-semibold"
                        >
                          <IconExternalLink color="grey" />
                        </a>
                        <div className="text-sm font-normal text-gray-500 dark:text-gray-300">
                          <time className="mb-1 text-xs font-normal text-gray-400 ">
                            2024 - Present
                          </time>
                          <CardTitle>Singapore Armed Forces (SAF)</CardTitle>
                          <CardDescription>
                            <p>Government Administration (Singapore)</p>
                            <p>
                              Full-Time National Servicemen (NSF) - Admin
                              Support Assistant (ASA)
                            </p>
                          </CardDescription>
                          <div className="space-y-0.5 mt-5">
                            <p>- No information yet</p>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </li>
                  <li className="ms-6 py-4">
                    <span className="absolute flex items-center justify-center size-10 mt-2 bg-blue-100 rounded-full -start-5 ring-4 ring-white dark:ring-amber-500 dark:bg-blue-900">
                      <img
                        className="rounded-full shadow-lg"
                        src="https://upload.wikimedia.org/wikipedia/en/7/72/Housing_and_Development_Board_%28logo%29.png"
                        alt="Housing & Development Board Logo"
                      />
                    </span>
                    <motion.div
                      key={2}
                      className=""
                      initial={{ opacity: 0, x: -10 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 1 * 0.3 }}
                      viewport={{ amount: 0.1 }}
                    >
                      <div className="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg ml-5 shadow-sm sm:flex dark:bg-neutral-800 dark:border-neutral-600">
                        <a
                          href=""
                          className="mb-1 text-xs sm:order-last sm:mb-0 font-semibold"
                        >
                          <IconExternalLink color="grey" />
                        </a>
                        <div className="text-sm font-normal text-gray-500 dark:text-gray-300">
                          <time className="mb-1 text-xs font-normal text-gray-400 ">
                            Mar 2023 - Mar 2024
                          </time>
                          <CardTitle>
                            Housing & Development Board (HDB)
                          </CardTitle>
                          <CardDescription>
                            <p>Government Administration (Singapore)</p>
                            <p>Intern - UI/UX Engineer</p>
                          </CardDescription>
                          <div className="space-y-0.5 mt-5">
                            <p>
                              - Developed and maintained the user interface
                              components of HDB's digital services and the
                              design system
                            </p>
                            <p>
                              - Designed and developed centralized front-end
                              scripts and services to govern the visual
                              consistency of HDB's digital services in the cloud
                            </p>
                            <p>
                              - Conducted research and implemented new
                              frameworks to automate processes and improve
                              workflow efficiency using the latest technology
                              stack
                            </p>
                            <p>
                              - Utilized Continuous Integration and Continuous
                              Delivery (CI/CD) tools for development automation
                            </p>
                            <p>
                              - Researched and developed CAPTCHA solutions to
                              enhance user security and experience with minimal
                              interruptions
                            </p>
                            <p>
                              - Collaborated with stakeholders, including
                              Product Owners, Scrum Masters, UX Designers, and
                              Engineers, to develop and implement useful
                              features for Singapore Citizens and staff
                            </p>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </li>
                  <li className="ms-6 py-4">
                    <span className="absolute flex items-center justify-center size-10 mt-2 bg-blue-100 rounded-full -start-5 ring-4 ring-white dark:ring-amber-500 dark:bg-blue-900">
                      <img
                        className="rounded-full shadow-lg"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2b/DeloitteNewSmall.png/220px-DeloitteNewSmall.png"
                        alt="Deloitte Logo"
                      />
                    </span>
                    <motion.div
                      key={3}
                      className=""
                      initial={{ opacity: 0, x: -10 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 1 * 0.4 }}
                      viewport={{ amount: 0.1 }}
                    >
                      <div className="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg ml-5 shadow-sm sm:flex dark:bg-neutral-800 dark:border-neutral-600">
                        <a
                          href=""
                          className="mb-1 text-xs sm:order-last sm:mb-0 font-semibold"
                        >
                          <IconExternalLink color="grey" />
                        </a>
                        <div className="text-sm font-normal text-gray-500 dark:text-gray-300">
                          <time className="mb-1 text-xs font-normal text-gray-400 ">
                            Jan 2021 - Mar 2021
                          </time>
                          <CardTitle>Deloitte</CardTitle>
                          <CardDescription>
                            <p>Accounting Firm (Singapore / SEA)</p>
                            <p>
                              Intern - Marketing & Comms - South East Asia
                              E-marketing
                            </p>
                          </CardDescription>
                          <div className="space-y-0.5 mt-5">
                            <p>- words words words</p>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </li>
                  {/* <li className="ms-6 pt-5"></li> */}
                </ol>

                {/* <TimelineLine /> */}
              </Card>
            </TabsContent>

            {/* <<<------------------------------>>*/}

            <TabsContent value="education">
              <Card className="bg-slate-50 dark:bg-neutral-900">
                <ol className="relative ml-12 mr-12">
                  <div className="absolute top-0 left-0 bottom-0 w-px h-full bg-gray-200 dark:bg-amber-500"></div>
                  <li className="ms-6 py-4">
                    <span className="absolute flex items-center justify-center size-10 mt-2 bg-blue-100 rounded-full -start-5 ring-4 ring-white dark:ring-amber-500 dark:bg-blue-900">
                      <img
                        className="rounded-full shadow-lg"
                        src="https://dbcsingapore.org/wp-content/uploads/2021/12/SiT.png"
                        alt="Singapore Institue of Technology Logo"
                      />
                    </span>
                    <motion.div
                      key={1}
                      className=""
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 1 * 0.2 }}
                      viewport={{ amount: 0.8 }}
                    >
                      <div className="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg ml-5 shadow-sm sm:flex dark:bg-neutral-800 dark:border-neutral-600">
                        <a
                          href=""
                          className="mb-1 text-xs sm:order-last sm:mb-0 font-semibold"
                        >
                          <IconExternalLink color="grey" />
                        </a>
                        <div className="text-sm font-normal text-gray-500 dark:text-gray-300">
                          <time className="mb-1 text-xs font-normal text-gray-400 ">
                            Matriculating in 2026
                          </time>
                          <CardTitle>
                            Singapore Institute of Technology (SIT) & University
                            of Glasgow (UoG)
                          </CardTitle>
                          <CardDescription>
                            <p>BSc (Hons) in Computing Science</p>
                          </CardDescription>
                          <div className="space-y-0.5 mt-5">
                            <p>- words words words</p>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </li>
                  <li className="ms-6 py-4">
                    <span className="absolute flex items-center justify-center size-10 mt-2 bg-blue-100 rounded-full -start-5 ring-4 ring-white dark:ring-amber-500 dark:bg-blue-900">
                      <img
                        className="rounded-full shadow-lg"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDYvv1TKMF9YwcgQZqXY2vJylaHaASQMrLBw&s"
                        alt="Republic Poly Logo"
                      />
                    </span>
                    <motion.div
                      key={2}
                      className=""
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 1 * 0.3 }}
                      viewport={{ amount: 0.8 }}
                    >
                      <div className="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg ml-5 shadow-sm sm:flex dark:bg-neutral-800 dark:border-neutral-600">
                        <a
                          href=""
                          className="mb-1 text-xs sm:order-last sm:mb-0 font-semibold"
                        >
                          <IconExternalLink color="grey" />
                        </a>
                        <div className="text-sm font-normal text-gray-500 dark:text-gray-300">
                          <time className="mb-1 text-xs font-normal text-gray-400 ">
                            Apr 2021 - Apr 2024
                          </time>
                          <CardTitle>Republic Polytechnic (RP)</CardTitle>
                          <CardDescription>
                            <p>Diploma in Information Technology</p>
                          </CardDescription>
                          <div className="space-y-0.5 mt-5">
                            <p>- words words words</p>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </li>
                  <li className="ms-6 py-4">
                    <span className="absolute flex items-center justify-center size-10 mt-2 bg-blue-100 rounded-full -start-5 ring-4 ring-white dark:ring-amber-500 dark:bg-blue-900">
                      <img
                        className="rounded-full shadow-lg"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Institute_of_Technical_Education_%28logo%29.svg/1200px-Institute_of_Technical_Education_%28logo%29.svg.png"
                        alt="ITE Logo"
                      />
                    </span>
                    <motion.div
                      key={3}
                      className=""
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 1 * 0.4 }}
                      viewport={{ amount: 0.8 }}
                    >
                      <div className="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg ml-5 shadow-sm sm:flex dark:bg-neutral-800 dark:border-neutral-600">
                        <a
                          href=""
                          className="mb-1 text-xs sm:order-last sm:mb-0 font-semibold"
                        >
                          <IconExternalLink color="grey" />
                        </a>
                        <div className="text-sm font-normal text-gray-500 dark:text-gray-300">
                          <time className="mb-1 text-xs font-normal text-gray-400 ">
                            Jan 2017 - Apr 2021
                          </time>
                          <CardTitle>
                            Institute of Technical Education (ITE)
                          </CardTitle>
                          <CardDescription>
                            <p>Higher Nitec in IT Application Development</p>
                            <p>Nitec in Social Media and Web Development</p>
                          </CardDescription>
                          <div className="space-y-0.5 mt-5">
                            <p>- words words words</p>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </li>
                </ol>

                {/* <TimelineLine /> */}
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </>
  );
}
