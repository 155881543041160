import "./assets/css/style.css";
import UserSessionContextProvider from "./components/contextProvider/usersession";
import { Routes, Route } from "react-router-dom";
import LoggedIn from "./components/pages/loggedin";
import Protected from "./components/pages/protected";
import ErrorBoundary from "./components/contextProvider/errorboundary";
import { NotFound } from "./components/pages/notfound";

export default function App() {
  return (
    <ErrorBoundary>
      <UserSessionContextProvider>
        <Routes>
          {/* <Route path="*" element={<NotFound />} /> */}
          <Route path="/" element={<LoggedIn />} />
          <Route path="/protected" element={<Protected />} />
        </Routes>
      </UserSessionContextProvider>
    </ErrorBoundary>
  );
}
