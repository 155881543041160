import React, { useState } from "react";
import imageurl from "../../assets/images/test.png";
const projects = [
  {
    id: "01",
    title: "Name #1",
    description: "Application Development",
    image: imageurl,
  },
  {
    id: "02",
    title: "Name #2",
    description: "Web Development",
    image: imageurl,
  },
  {
    id: "03",
    title: "Name #3",
    description: "Web Development / Bot Development",
    image: imageurl,
  },
  {
    id: "04",
    title: "Name #4",
    description: "Web Development",
    image: imageurl,
  },
];

const ProjectsList = () => {
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: React.MouseEvent) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div className=" dark:bg-neutral-950">
      <div
        className="relative mx-auto max-w-7xl px-4 py-12 text-slate-800 dark:text-white"
        onMouseMove={handleMouseMove}
      >
        <div className="mx-auto space-y-8">
          <h2 className="max-w-lg text-4xl font-bold md:text-5xl pb-5">
            Featured
            <span className="text-slate-400 dark:text-amber-500">
              {" "}
              Projects
            </span>
          </h2>
        </div>
        <div className="max-w-6xl mx-auto space-y-8 cursor-pointer">
          {projects.map((project) => (
            <div>
              {/* Mobile Layout */}
              <div className="sm:hidden">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-40 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold text-center mb-2">
                  {project.title}
                </h3>
                <p className="text-sm text-center text-gray-600 dark:text-gray-400">
                  {project.description}
                </p>
              </div>
              <div
                key={project.id}
                className="flex items-center justify-between group border-b border-gray-200 dark:border-gray-700 pb-10 pt-10"
                onMouseEnter={() => setPreviewImage(project.image)}
                onMouseLeave={() => setPreviewImage(null)}
              >
                {/* Left Side */}
                <div className="flex items-center space-x-4">
                  <span className="text-2xl font-medium text-gray-400">
                    {project.id}
                  </span>
                  <h3 className="text-3xl font-semibold">{project.title}</h3>
                </div>

                {/* Right Side */}
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {project.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Preview Image for Desktop */}

        {previewImage && (
          <div
            className="hidden sm:block fixed pointer-events-none w-64 h-40 bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-150"
            style={{
              top: cursorPosition.y - 80, // Center the image vertically
              left: cursorPosition.x - 128, // Center the image horizontally
            }}
          >
            <img
              src={previewImage}
              alt="Project Preview"
              className="w-full h-full object-cover"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectsList;
