import { IconArrowUpRight } from "@tabler/icons-react";
import React, { useState } from "react";

const AboutMe = () => {
  const [showMap, setShowMap] = useState(false);

  return (
    <div className="dark:bg-neutral-950">
      <div className="pb-48 flex items-center justify-center text-white dark:text-white">
        <div className="max-w-5xl w-full p-6 grid grid-cols-1 md:grid-cols-2 gap-10">
          {/* Left Section */}
          <div>
            <h2 className="text-gray-400 text-sm uppercase mb-4">About Me</h2>
            <h1 className="text-4xl font-bold text-slate-800 dark:text-white mb-4">
              Hi, I'm <span className="text-amber-500">Izzat.</span>
            </h1>
            <ul className="space-y-2 text-slate-700 dark:text-white">
              <li className="flex items-center">
                <span className="mr-2">📍</span>
                Based in&nbsp;
                <span
                  className="text-amber-500 cursor-pointer relative"
                  onMouseEnter={() => setShowMap(true)}
                  onMouseLeave={() => setShowMap(false)}
                >
                  Singapore
                  {showMap && (
                    <div className="absolute left-0 mt-2 w-48 h-32 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-lg p-2">
                      <img
                        src="https://media.istockphoto.com/id/481491778/vector/grey-map-of-singapore.jpg?s=612x612&w=0&k=20&c=Dr13iVi9HY5Hvk5b9LTSB_SORiM5NbN1ndr1yfJoRTA="
                        alt="Country Map"
                        className="w-full h-full rounded-md"
                      />
                    </div>
                  )}
                </span>
              </li>
              {/* <li className="flex items-center">
              <span className="mr-2">💻</span> Currently a 
            </li> */}
              <li className="flex items-center">
                <span className="mr-2">📅</span> 5+ years of experience
              </li>
            </ul>
            <p className="text-slate-700 dark:text-white mt-6">
              WORDS WORDS WORDS
            </p>
            {/* <p className="text-slate-700 mt-6">
            As a dedicated developer based in the heart of Bavaria, I bring
            German precision and creative innovation to every project. I
            specialize in creating robust, scalable web applications that
            combine cutting-edge technology with intuitive user experiences.
          </p> */}
            <div className="mt-6 flex items-center gap-4">
              <button className="flex items-center px-4 py-2 bg-amber-500 text-white rounded-lg hover:bg-amber-600">
                View resume
                <span className="ml-2 inline-block">
                  <IconArrowUpRight />
                </span>
              </button>
              <button className="flex items-center px-4 py-2 border border-amber-500 text-amber-500 rounded-lg hover:bg-amber-500 hover:text-white">
                View Projects
              </button>
            </div>
          </div>
          {/* Right Section */}
          <div>
            <div className="mb-6">
              <h1 className="text-2xl font-semibold text-slate-800 dark:text-white">
                My Tech Stack 🛠️
              </h1>
              <h3 className="text-lg font-semibold text-slate-800 dark:text-white">
                🧑‍💻 Languages, Runtimes & Frameworks
              </h3>
              <div className="flex flex-wrap gap-2 mt-2">
                {[
                  "HTML/CSS",
                  "JavaScript",
                  "TypeScript",
                  "Python",
                  "Java",
                  "React",
                  "Next.js",
                  "Node.js",
                  "Express",
                  "Bun.js",
                  "Discord.js",
                  "Tailwind",
                  "SASS",
                  "jQuery",
                  "Swift",
                ].map((skill) => (
                  <span
                    key={skill}
                    className="px-3 py-1 bg-gray-800 text-sm rounded-lg"
                  >
                    {skill}
                  </span>
                ))}
              </div>
              <h3 className="mt-3 text-lg font-semibold text-slate-800 dark:text-white">
                ☁️ Cloud & Deployment Services
              </h3>
              <div className="flex flex-wrap gap-2 mt-2">
                {[
                  "Cloudflare",
                  "Cloudflare Workers",
                  "Vercel",
                  "Firebase",
                  "mySQL",
                  "Turso",
                  "MongoDB",
                ].map((skill) => (
                  <span
                    key={skill}
                    className="px-3 py-1 bg-gray-800 text-sm rounded-lg"
                  >
                    {skill}
                  </span>
                ))}
              </div>
              <h3 className="mt-3 text-lg font-semibold text-slate-800 dark:text-white">
                🖥️ Development Environments & Tools
              </h3>
              <div className="flex flex-wrap gap-2 mt-2">
                {[
                  "Visual Studio Code",
                  "JetBrain IDE",
                  "Git",
                  "Docker Desktop",
                  "Github Copilot",
                  "CLI Tools (Node CLI, Docker CLI)",
                ].map((skill) => (
                  <span
                    key={skill}
                    className="px-3 py-1 bg-gray-800 text-sm rounded-lg"
                  >
                    {skill}
                  </span>
                ))}
              </div>
              <h3 className="mt-3 text-lg font-semibold text-slate-800 dark:text-white">
                ⚙️ DevOps & Automation
              </h3>
              <div className="flex flex-wrap gap-2 mt-2">
                {["Github", "Gitlabs", "Github Actions", "Docker"].map(
                  (skill) => (
                    <span
                      key={skill}
                      className="px-3 py-1 bg-gray-800 text-sm rounded-lg"
                    >
                      {skill}
                    </span>
                  )
                )}
              </div>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-slate-700 dark:text-white">
                Speciality
              </h3>
              <ul className="mt-2 space-y-2 text-slate-500 dark:text-white">
                {[
                  "Full Stack Development",
                  "Web Design & Development",
                  "Application Development",
                ].map((service) => (
                  <li key={service} className="flex items-center">
                    <span className="text-amber-500 mr-2">➜</span> {service}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
