// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/HKGroteskWide-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/HKGroteskWide-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "HKGroteskWide-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HKGroteskWide-Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/fonts.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,+DAA8D;EAC9D,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,+DAAiE;EACjE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: \"HKGroteskWide-Bold\";\n  src: url(\"../fonts/HKGroteskWide-Bold.otf\") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"HKGroteskWide-Regular\";\n  src: url(\"../fonts/HKGroteskWide-Regular.otf\") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
