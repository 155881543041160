import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  IconSun,
  IconMoon,
  IconLockOpen,
  IconChevronRight,
  IconArrowBearLeft,
} from "@tabler/icons-react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function Unauthenticated() {
  type ToggleOptionsType = "light" | "dark";
  const [theme, setTheme] = useState<ToggleOptionsType>(() => {
    // Set initial theme based on localStorage or default to 'light'
    return localStorage.getItem("theme") === "dark" ? "dark" : "light";
  });

  const storedAuth = localStorage.getItem("isLoggedIn");

  // useEffect(() => {
  //   const currentHour = new Date().getHours();

  //   // Set theme based on time
  //   if (currentHour >= 7 && currentHour <= 18) {
  //     setTheme("light");
  //     document.documentElement.classList.remove("dark");
  //   } else {
  //     setTheme("dark");
  //     document.documentElement.classList.add("dark");
  //   }
  // }, []);

  // Apply the dark mode class to the html element based on 'selected' state
  useEffect(() => {
    const html = document.documentElement;
    if (theme === "dark") {
      html.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      html.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [, setOpenDialog] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [token, setToken] = useState(""); // Tracks token input
  const [error, setError] = useState<string | null>(null);
  const [, setIsLoggedIn] = useState(false); // Tracks user authentication state
  const navigate = useNavigate();

  const cancelButtonRef = useRef(null);

  const handleDivClick = () => {
    setOpenDialog(true);
  };

  const handleLogin = () => {
    setOpenLogin(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    if (!token) {
      setError("Please enter a key.");
      return;
    }
    e.preventDefault();
    try {
      // Fetch request with Authorization header for Bearer token
      const response = await fetch("https://api.izzat.xyz", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_API_SECRET_KEY}`, // Ensure correct environment variable
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        throw new Error("Invalid token or expired");
      }

      await response.json();
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", "true"); // Persist login status
      setOpenDialog(false);
      navigate("/"); // Navigate to logged-in route
    } catch (err) {
      setError("Token is invalid or has expired.");
    }
  };

  return (
    <div className="bg dark:bg-neutral-950">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8 top-0 right-0 absolute text-gray-900 dark:text-slate-100"
          aria-label="Global"
        >
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end ">
            <div
              style={{
                width: 25,
                height: 25,
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => {
                setTheme(theme === "light" ? "dark" : "light");
              }}
              className="mr-5"
            >
              <IconSun
                style={{
                  position: "absolute",
                  opacity: theme === "dark" ? 1 : 0,
                  transform: theme === "dark" ? "scale(1)" : "scale(0.5)",
                  transition: "opacity 0.3s ease, transform 0.3s ease",
                }}
                size={25}
              />
              <IconMoon
                style={{
                  position: "absolute",
                  opacity: theme === "light" ? 1 : 0,
                  transform: theme === "light" ? "scale(1)" : "scale(0.5)",
                  transition: "opacity 0.3s ease, transform 0.3s ease",
                }}
                size={25}
              />
            </div>

            {storedAuth === "true" ? (
              <></>
            ) : (
              <a
                onClick={handleLogin}
                className="text-sm font-semibold leading-6 "
                style={{ cursor: "pointer" }}
                href="#"
              >
                <IconLockOpen style={{ display: "inline" }} size={25} />
                <IconChevronRight style={{ display: "inline" }} size={25} />
              </a>
            )}
          </div>
        </nav>
        <Dialog
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white dark:text-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Izzat</span>
                <img className="h-8 w-auto" src="" alt="" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root dark:text-white">
              <div className="-my-6 divide-y divide-gray-500/10">
                {/* <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div> */}
                <div className="py-6">
                  <a
                    onClick={() => {
                      setTheme(theme === "light" ? "dark" : "light");
                    }}
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900  hover:bg-gray-50"
                  >
                    Dark Mode
                  </a>
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      {storedAuth === "true" ? (
        <></>
      ) : (
        <IconArrowBearLeft
          className="test hidden lg:flex dark:text-slate-100"
          size={35}
        />
      )}
      <div className="relative isolate px-6 lg:px-8 text-gray-600 dark:text-slate-100">
        <div className="flex h-screen">
          {/* <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56"> */}
          <div className="m-auto">
            <div className="text-center" style={{ paddingTop: "70px" }}>
              <p className="text-lg leading-8 ">Hello, I'm</p>
              <h1 className="text-4xl font-bold tracking-tight  sm:text-6xl intro-name dark:text-amber-500">
                Izzat
              </h1>
            </div>
            <div
              className="hidden sm:mb-8 sm:flex sm:justify-center mt-5 text-gray-600 dark:text-slate-100"
              onClick={handleDivClick}
              style={{ cursor: "pointer" }}
            >
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 ring-1 ring-gray-900/10 hover:ring-gray-900/20  dark:ring-slate-200/10 dark:hover:ring-slate-200/20">
                <div className="status__light">
                  <div className="status__light__ring"></div>
                  <div className="status__light__led"></div>
                </div>
                <p className="status__message">Currently Not Available</p>
              </div>
            </div>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              {/* <a
                href="#"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get started
              </a> */}
              <a
                href="https://www.linkedin.com/in/muhammad-izzat-sg/"
                className="text-sm font-semibold leading-6 text-gray-600 dark:text-white"
                target="blank"
              >
                View LinkedIn Profile <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpenLogin}
        open={openLogin}
      >
        <div className="fixed inset-0 bg-black bg-opacity-50">
          <Dialog.Panel className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6 w-auto">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">
                  Greetings fellow traveler &#x1F44B;
                </h2>
                <button
                  type="button"
                  className="text-gray-700 hover:text-gray-900"
                  data-modal-toggle="authentication-modal"
                  onClick={() => setOpenLogin(false)}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <p className="text-sm">
                You're visiting a site that's still in development.
              </p>
              <p className="text-sm">
                Enter your access key below to unlock the full experience.
              </p>
              <input
                type="password"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                placeholder="••••••••"
                className="w-full mt-4 p-2 border rounded-lg focus:ring focus:ring-amber-300"
              />
              <button
                onClick={handleSubmit}
                className="w-full mt-4 p-2 bg-amber-500 text-white rounded-lg hover:bg-amber-600"
              >
                Enter
              </button>
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}
