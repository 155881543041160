import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const UserSessionContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [, setIsLoggedIn] = useState(false); // Tracks user authentication state
  const [dialogOpen, setDialogOpen] = useState(true); // Tracks dialog visibility
  const [token, setToken] = useState(""); // Tracks token input
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const API_KEY = process.env.REACT_APP_API_SECRET_KEY!;

  useEffect(() => {
    const storedAuth = localStorage.getItem("isLoggedIn");
    if (storedAuth === "true") {
      setIsLoggedIn(true);
      setDialogOpen(false);
    } else if (location.pathname !== "/protected") {
      navigate("/protected"); // Redirect to a free page if not logged in
    }
  }, [location, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    if (!token) {
      setError("Please enter a key.");
      return;
    }
    e.preventDefault();
    try {
      // Fetch request with Authorization header for Bearer token
      const response = await fetch("https://api.izzat.xyz", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_API_SECRET_KEY}`, // Ensure correct environment variable
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        throw new Error("Invalid token or expired");
      }

      await response.json();
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", "true"); // Persist login status
      setDialogOpen(false);
      navigate("/"); // Navigate to logged-in route
    } catch (err) {
      setError("Token is invalid or has expired.");
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    navigate("/protected"); // Navigate to the free page when closing dialog
  };

  return (
    <>
      {/* Dialog */}
      {dialogOpen && (
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
          <Dialog.Panel className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6 w-auto">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">
                  Greetings fellow traveler &#x1F44B;
                </h2>

                <button
                  onClick={handleCloseDialog}
                  className="text-gray-700 hover:text-gray-900"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              <p className="text-sm">
                You're visiting a site that's still in development.
              </p>
              <p className="text-sm">
                Enter your access key below to unlock the full experience.
              </p>
              <input
                type="password"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                placeholder="••••••••"
                className="w-full mt-4 p-2 border rounded-lg focus:ring focus:ring-amber-300"
              />
              <button
                onClick={handleSubmit}
                className="w-full mt-4 p-2 bg-amber-500 text-white rounded-lg hover:bg-amber-600"
              >
                Enter
              </button>
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            </div>
          </Dialog.Panel>
        </Dialog>
      )}

      {/* Render children */}
      {!dialogOpen && children}
    </>
  );
};

export default UserSessionContextProvider;
