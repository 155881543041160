import ExperienceTab from "../hero/experience-tab";
import { Footer } from "../hero/footer";
import Intro from "../hero/intro";
import AboutMeSection from "../section/aboutme";
import Projects from "../section/projects";
import AboutMe from "../ui/aboutme";
import ProjectsList from "../ui/projectlist";

export default function LoggedIn() {
  return (
    <>
      <Intro />
      <AboutMeSection />
      <AboutMe />
      <ExperienceTab />
      <Projects />
      <ProjectsList />
      <Footer />
    </>
  );
}
