// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-hero {
  background-color: inherit;
}

.section-title {
  font-family: "HKGroteskWide-Bold";
  -webkit-user-select: none;
          user-select: none;
}

.section-para {
  padding-top: 10%;
  font-family: "HKGroteskWide-Regular";
  font-size: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/sections.css"],"names":[],"mappings":"AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iCAAiC;EACjC,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,oCAAoC;EACpC,eAAe;AACjB","sourcesContent":["@import url(./fonts.css);\n\n.section-hero {\n  background-color: inherit;\n}\n\n.section-title {\n  font-family: \"HKGroteskWide-Bold\";\n  user-select: none;\n}\n\n.section-para {\n  padding-top: 10%;\n  font-family: \"HKGroteskWide-Regular\";\n  font-size: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
