import { Component, ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  resetError = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center h-screen bg-red-100 p-8">
          <h1 className="text-4xl font-bold text-amber-500 mb-4">
            Oops! Something went wrong 😞
          </h1>
          <p className="text-lg text-gray-700">
            {this.state.error?.message || "An unexpected error has occurred."}
          </p>
          <div className="mt-6 flex items-center gap-4">
            <a
              href=".."
              className="text-sm mt-6 font-semibold leading-6 text-gray-600 dark:text-white"
            >
              <span aria-hidden="true">←</span> back to home
            </a>
            <button
              onClick={this.resetError}
              className="mt-6 bg-amber-600 text-white px-4 py-2 rounded hover:bg-amber-700"
            >
              Try Again
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
